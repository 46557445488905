import Helmet from "react-helmet"
import React from "react"
import OptionalOfferPop from "src/components/modules/optionalofferPop"
import Optionalofferint from "src/components/modules/optionalofferint"


export default function Optional() {
  const DeData = import("./data/opoffergerman.json");
  const FrData = import("./data/opofferfrench.json");
  module = <OptionalOfferPop></OptionalOfferPop>;
  if (typeof window !== 'undefined' && typeof window.navigator !== 'undefined') {
     let lang = navigator.language.slice(0,2);
     if(lang == 'de'){
        module = <Optionalofferint data={DeData} language='de'></Optionalofferint>
      }
      else if(lang == 'fr'){
        module = <Optionalofferint data={FrData} language='fr'></Optionalofferint>
      }
   }
  return(
    <section>
      <Helmet>
      <style type="text/css">{`
        footer.footer {
          position: absolute;
          bottom: 10px;
          left: 50%;
          transform: translateX(-50%);
          font-size: 12px;
          width: 600px;
          z-index: 3;
        }
    `}</style>
        <body class="white" />
        <title>Optional Offer- Get Freshy Homepage Newtab- freshysearch.com</title>
      </Helmet>
      {module}
    </section>
  )
}
