import React from "react"
import { Link } from "gatsby"
import JSONData from "src/../branding/site-metadata.json"
import OpData from "./data/optionaloffer.json"
import Styles from "./css/optionalpop.module.scss"
import {isChrome,isFirefox} from "../lpscripts/browserdetect"
import Footerlinks from "src/components/footerlinks/footer"

class OptionalofferPop extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      isChrome: isChrome(),
      isFirefox: isFirefox(),
      display: 'block',
      pageData: props.data,
      data: OpData
    }
    if(isChrome()){
      const browserData = Object.assign(this.state.data, this.state.data.chrome);
      this.setState({data: browserData});
    }
    if(isFirefox()){
      const browserData = Object.assign(this.state.data, this.state.data.firefox);
      this.setState({data: browserData});
    }
    if(this.state.pageData){
      this.state.pageData.then(data => {
        const instanceData = Object.assign(OpData, data);
        this.setState({ data: instanceData});
        if(isChrome()){
          const browserData = Object.assign(this.state.data, this.state.data.chrome);
          this.setState({data: browserData});
        }
        if(isFirefox()){
          const browserData = Object.assign(this.state.data, this.state.data.firefox);
          this.setState({data: browserData});
        }
      });
    }
  }
  openNewTab(){
    if (typeof window !== `undefined`) {
      let current_url = (new URL(window.location));
			if( navigator.userAgent.toLowerCase().indexOf('firefox') > -1 ) {
					let nt = (current_url.href.indexOf('nt=') !== -1) ? current_url.searchParams.get("nt") : '' ;
					if(nt) {
						window.open(nt,"_self");
					}
			}
			else if( navigator.userAgent.toLowerCase().indexOf('chrome') > -1 ) {
				window.location.hash = '#newtab';
			}
      window.dispatchEvent(new Event('openNativePage'));
    }
  }

  render() {
    return (
      <>
      <div className={Styles.optionalofferpop}>
        {/*-------Top-------*/}
        <div className={Styles.wrap}>
          <div className={Styles.background}>
          </div>
          <img className={Styles.prodLogo} src={this.state.data.product}></img>
          <div className={Styles.modal}>
            <div className={Styles.banner}>{this.state.data.banner}</div>
            <p className={Styles.headline}>{this.state.data.headline}</p>
            <p className={Styles.subhead}>{this.state.data.subhead}</p>
            <p className={Styles.compliance}>{this.state.data.compliance}</p>
            <button className={Styles.opBtn}>{this.state.data.opBtn}</button>
            <a href="#" className={Styles.nothanks}>{this.state.data.nothanks}</a>
            <div className={Styles.disclaimer}>By installing Freshy Homepage, you acknowledge the <a href="https://freshysearch.com/terms-of-service/">Terms of Service</a> and <a href="https://freshysearch.com/privacy-policy/">Privacy Policy</a>.</div>
          </div>
        <Footerlinks></Footerlinks>
      </div>
    </div>
</>
    )
  }
}

export default OptionalofferPop
